//
// Utility classes
// --------------------------------------------------

// Quick floats
.pull-right {
  float: right;
}
.pull-left {
  float: left;
}

// Toggling content
.hide {
  display: none;
}
.show {
  display: block;
}

// Visibility
.invisible {
  visibility: hidden;
}

// For Affix plugin
.affix {
  position: fixed;
}
