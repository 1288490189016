@import "../../bootstrap/variables.less";
@import "../../bootstrap/mixins.less";
@import "global.variables.less";

.modal form {
  // Form inside a modal dialog doesn't need bottom margin
  margin-bottom: 0;
}

// If an input is invalid and focused, but the user hasn't attempted to enter anything yet (.ng-pristine)
// then don't make the input look invalid. This rule resets the input to a normal focused state.
input,
textarea,
select {
  &.ng-pristine:invalid:focus {
    border-color: rgba(82, 168, 236, 0.8);
    color: @offBlack;
    outline: 0;
    outline: thin dotted \9; /* IE6-9 */
    .box-shadow(~"inset 0 1px 1px rgba(0,0,0,.075), 0 0 8px rgba(82,168,236,.6)");
  }
}

select,
textarea,
input[type="text"],
input[type="password"],
input[type="datetime"],
input[type="datetime-local"],
input[type="date"],
input[type="month"],
input[type="time"],
input[type="week"],
input[type="number"],
input[type="email"],
input[type="url"],
input[type="search"],
input[type="tel"],
input[type="color"],
.uneditable-input {
  color: @offBlack;
}

// Dropdowns
// ---------

.dropdown-menu > li > a {
  padding: 4px 20px;
}

.dropdown-menu > li > a:hover,
.dropdown-menu > li > a:focus {
  text-decoration: none;
  filter: none;
  background-image: none;
  background-color: #e9e9e9;
  color: @offBlack;
}

.dropdown-menu > .active > a,
.dropdown-menu > .active > a:hover,
.dropdown-menu > .active > a:focus {
  text-decoration: none;
  outline: 0;
  filter: none;
  background-image: none;
  background-color: #f3f3f3;
  color: @offBlack;
  cursor: default;
}

// Navbar

.navbar-fixed-top .navbar-inner {
  padding: 0 10px 0 20px !important; // Override extra padding added by Bootstrap on smaller screens
}

.btn-toolbar.primary-nav {
  margin-top: 0;
  margin-bottom: 0;
}

// Wider modals

.modal-wide {
  width: 960px;
  margin-left: -480px;
}

// Force cursor pointer even when <a> has no href attribute
.nav a {
  cursor: pointer;
}

//Remove table border-radius
.table-bordered {
  border-radius: 0;
}

.table-bordered thead:first-child tr:first-child > th:first-child,
.table-bordered tbody:first-child tr:first-child > td:first-child,
.table-bordered tbody:first-child tr:first-child > th:first-child {
  border-top-left-radius: 0;
}

.table-bordered thead:first-child tr:first-child > th:last-child,
.table-bordered tbody:first-child tr:first-child > td:last-child,
.table-bordered tbody:first-child tr:first-child > th:last-child {
  border-top-right-radius: 0;
}

.table-bordered thead:last-child tr:last-child > th:first-child,
.table-bordered tbody:last-child tr:last-child > td:first-child,
.table-bordered tbody:last-child tr:last-child > th:first-child,
.table-bordered tfoot:last-child tr:last-child > td:first-child,
.table-bordered tfoot:last-child tr:last-child > th:first-child {
  border-bottom-left-radius: 0;
}

.table-bordered thead:last-child tr:last-child > th:last-child,
.table-bordered tbody:last-child tr:last-child > td:last-child,
.table-bordered tbody:last-child tr:last-child > th:last-child,
.table-bordered tfoot:last-child tr:last-child > td:last-child,
.table-bordered tfoot:last-child tr:last-child > th:last-child {
  border-bottom-right-radius: 0;
}

// Support for disabled attribute on fieldset
fieldset[disabled] input,
fieldset[disabled] select {
  cursor: not-allowed;
  background-color: @inputDisabledBackground;
}

// Support for disabled attribute on buttons
fieldset[disabled] button {
  cursor: not-allowed;
}
