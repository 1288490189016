//
// Responsive: Navbar
// --------------------------------------------------

// TABLETS AND BELOW
// -----------------
@media (max-width: @navbarCollapseWidth) {
  // UNFIX THE TOPBAR
  // ----------------
  // Remove any padding from the body
  body {
    padding-top: 0;
  }
  // Unfix the navbars
  .navbar-fixed-top,
  .navbar-fixed-bottom {
    position: static;
  }
  .navbar-fixed-bottom {
    margin-top: @baseLineHeight;
  }
  .navbar .container {
    width: auto;
    padding: 0;
  }
  // Account for brand name
  .navbar .brand {
    padding-left: 10px;
    padding-right: 10px;
    margin: 0 0 0 -5px;
  }

  // COLLAPSIBLE NAVBAR
  // ------------------
  // Nav collapse clears brand
  .nav-collapse {
    clear: both;
  }
  // Block-level the nav
  .nav-collapse .nav {
    float: none;
    margin: 0 0 (@baseLineHeight / 2);
  }
  .nav-collapse .nav > li {
    float: none;
  }
  .nav-collapse .nav > li > a {
    margin-bottom: 2px;
  }
  .nav-collapse .nav > .divider-vertical {
    display: none;
  }
  .nav-collapse .nav .nav-header {
    color: @navbarText;
    text-shadow: none;
  }
  // Nav and dropdown links in navbar
  .nav-collapse .nav > li > a,
  .nav-collapse .dropdown-menu a {
    padding: 9px 15px;
    font-weight: bold;
    color: @navbarLinkColor;
    .border-radius(3px);
  }
  // Buttons
  .nav-collapse .btn {
    padding: 4px 10px 4px;
    font-weight: normal;
    .border-radius(@baseBorderRadius);
  }
  .nav-collapse .dropdown-menu li + li a {
    margin-bottom: 2px;
  }
  .nav-collapse .nav > li > a:hover,
  .nav-collapse .nav > li > a:focus,
  .nav-collapse .dropdown-menu a:hover,
  .nav-collapse .dropdown-menu a:focus {
    background-color: @navbarBackground;
  }
  // Buttons in the navbar
  .nav-collapse.in .btn-group {
    margin-top: 5px;
    padding: 0;
  }
  // Dropdowns in the navbar
  .nav-collapse .dropdown-menu {
    position: static;
    top: auto;
    left: auto;
    float: none;
    display: none;
    max-width: none;
    margin: 0 15px;
    padding: 0;
    background-color: transparent;
    border: none;
    .border-radius(0);
    .box-shadow(none);
  }
  .nav-collapse .open > .dropdown-menu {
    display: block;
  }

  .nav-collapse .dropdown-menu:before,
  .nav-collapse .dropdown-menu:after {
    display: none;
  }
  .nav-collapse .dropdown-menu .divider {
    display: none;
  }
  .nav-collapse .nav > li > .dropdown-menu {
    &:before,
    &:after {
      display: none;
    }
  }
  // Forms in navbar
  .nav-collapse .navbar-form,
  .nav-collapse .navbar-search {
    float: none;
    padding: (@baseLineHeight / 2) 15px;
    margin: (@baseLineHeight / 2) 0;
    border-top: 1px solid @navbarBackground;
    border-bottom: 1px solid @navbarBackground;
    .box-shadow(~"inset 0 1px 0 rgba(255,255,255,.1), 0 1px 0 rgba(255,255,255,.1)");
  }
  // Pull right (secondary) nav content
  .navbar .nav-collapse .nav.pull-right {
    float: none;
    margin-left: 0;
  }
  // Hide everything in the navbar save .brand and toggle button */
  .nav-collapse,
  .nav-collapse.collapse {
    overflow: hidden;
    height: 0;
  }
  // Navbar button
  .navbar .btn-navbar {
    display: block;
  }

  // STATIC NAVBAR
  // -------------
  .navbar-static .navbar-inner {
    padding-left: 10px;
    padding-right: 10px;
  }
}

// DEFAULT DESKTOP
// ---------------

@media (min-width: @navbarCollapseDesktopWidth) {
  // Required to make the collapsing navbar work on regular desktops
  .nav-collapse.collapse {
    height: auto !important;
    overflow: visible !important;
  }
}
