//
// Tooltips
// --------------------------------------------------

// Base class
.tooltip {
  position: absolute;
  z-index: @zindexTooltip;
  display: block;
  visibility: visible;
  font-size: 11px;
  line-height: 1.4;
  .opacity(0);
  &.in {
    .opacity(80);
  }
  &.top {
    margin-top: -3px;
    padding: 5px 0;
  }
  &.right {
    margin-left: 3px;
    padding: 0 5px;
  }
  &.bottom {
    margin-top: 3px;
    padding: 5px 0;
  }
  &.left {
    margin-left: -3px;
    padding: 0 5px;
  }
}

// Wrapper for the tooltip content
.tooltip-inner {
  max-width: 200px;
  padding: 8px;
  color: @tooltipColor;
  text-align: center;
  text-decoration: none;
  background-color: @tooltipBackground;
  .border-radius(@baseBorderRadius);
}

// Arrows
.tooltip-arrow {
  position: absolute;
  width: 0;
  height: 0;
  border-color: transparent;
  border-style: solid;
}
.tooltip {
  &.top .tooltip-arrow {
    bottom: 0;
    left: 50%;
    margin-left: -@tooltipArrowWidth;
    border-width: @tooltipArrowWidth @tooltipArrowWidth 0;
    border-top-color: @tooltipArrowColor;
  }
  &.right .tooltip-arrow {
    top: 50%;
    left: 0;
    margin-top: -@tooltipArrowWidth;
    border-width: @tooltipArrowWidth @tooltipArrowWidth @tooltipArrowWidth 0;
    border-right-color: @tooltipArrowColor;
  }
  &.left .tooltip-arrow {
    top: 50%;
    right: 0;
    margin-top: -@tooltipArrowWidth;
    border-width: @tooltipArrowWidth 0 @tooltipArrowWidth @tooltipArrowWidth;
    border-left-color: @tooltipArrowColor;
  }
  &.bottom .tooltip-arrow {
    top: 0;
    left: 50%;
    margin-left: -@tooltipArrowWidth;
    border-width: 0 @tooltipArrowWidth @tooltipArrowWidth;
    border-bottom-color: @tooltipArrowColor;
  }
}
