//
// Wells
// --------------------------------------------------

// Base class
.well {
  min-height: 20px;
  padding: 19px;
  margin-bottom: 20px;
  background-color: @wellBackground;
  border: 1px solid darken(@wellBackground, 7%);
  .border-radius(@baseBorderRadius);
  .box-shadow(inset 0 1px 1px rgba(0,0,0,0.05));
  line-height: 21px;

  blockquote {
    border-color: #ddd;
    border-color: rgba(0, 0, 0, 0.15);
  }

  i {
    margin-right: 5px;
  }
}

// Sizes
.well-large {
  padding: 24px;
  .border-radius(@borderRadiusLarge);
}
.well-small {
  padding: 9px;
  .border-radius(@borderRadiusSmall);
}
