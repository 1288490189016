@import "./bootstrap/bootstrap.less";
@import "./bootstrap/responsive.less";

@import "./Global/styles/global.bootstrap-overrides.less";
@import "./Global/styles/global.site.less";
@import "./Global/styles/global.type.less";
@import "./Global/styles/global.variables.less";

@media print {
  @import "./Global/styles/global.print.less";
}
