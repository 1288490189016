// Stylesheet applies only to media=print
// --------------------------------------

// Hide top navbar
.navbar {
  display: none;
}

// Print elements with class="print-visible-inline"
.print-visible-inline {
  display: inline;
}

// Print elements with class="print-visible-block"
.print-visible-block {
  display: block;
}

.assessment-chart,
.summaries,
svg {
  page-break-inside: avoid;
}

// Override Bootstrap's insertion of urls after links
a[href]:after {
  content: none;
}

#userflow-ui {
  display: none;
}
