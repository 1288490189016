@import "../../bootstrap/variables.less";
@import "../../bootstrap/mixins.less";
@import "global.variables.less";

html {
  /* Header height 49 + 10 for breathing space */
  scroll-padding-top: 59px;
}

body {
  min-width: 990px;
  margin: 0;
  background-image: url(../images/background-pattern.png);
  background-repeat: repeat;
  -webkit-print-color-adjust: exact;
}

@media only screen and (-webkit-min-device-pixel-ratio: 2),
  only screen and (min-device-pixel-ratio: 2) {
  body {
    background-image: url(../images/background-pattern@2x.png);
    -webkit-background-size: 100px 100px;
    background-size: 100px 100px;
  }
}

a:focus {
  outline: none; /* Override outlining in Firefox */
}
