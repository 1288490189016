//
// Grid system
// --------------------------------------------------

// Fixed (940px)
#grid > .core(@gridColumnWidth, @gridGutterWidth);

// Fluid (940px)
#grid > .fluid(@fluidGridColumnWidth, @fluidGridGutterWidth);

// Reset utility classes due to specificity
[class*="span"].hide,
.row-fluid [class*="span"].hide {
  display: none;
}

[class*="span"].pull-right,
.row-fluid [class*="span"].pull-right {
  float: right;
}
