//
// Typography
// --------------------------------------------------

// Body text
// -------------------------

p {
  margin: 0 0 @baseLineHeight / 2;
}
.lead {
  margin-bottom: @baseLineHeight;
  font-size: @baseFontSize * 1.5;
  font-weight: 200;
  line-height: @baseLineHeight * 1.5;
}

// Emphasis & misc
// -------------------------

// Ex: 14px base font * 85% = about 12px
small {
  font-size: 85%;
}

strong {
  font-weight: bold;
}
em {
  font-style: italic;
}
cite {
  font-style: normal;
}

.text-warning {
  color: @warningText;
}
a.text-warning:hover,
a.text-warning:focus {
  color: darken(@warningText, 10%);
}

.text-error {
  color: @errorText;
}
a.text-error:hover,
a.text-error:focus {
  color: darken(@errorText, 10%);
}

.text-info {
  color: @infoText;
}
a.text-info:hover,
a.text-info:focus {
  color: darken(@infoText, 10%);
}

.text-success {
  color: @successText;
}
a.text-success:hover,
a.text-success:focus {
  color: darken(@successText, 10%);
}

.text-left {
  text-align: left;
}
.text-right {
  text-align: right;
}
.text-center {
  text-align: center;
}

// Headings
// -------------------------

h1,
h2,
h3,
h4,
h5,
h6 {
  margin: (@baseLineHeight / 2) 0;
  font-family: @headingsFontFamily;
  font-weight: @headingsFontWeight;
  line-height: @baseLineHeight;
  color: @headingsColor;
  text-rendering: optimizelegibility; // Fix the character spacing for headings
  small {
    font-weight: normal;
    line-height: 1;
    color: @grayLight;
  }
}

h1,
h2,
h3 {
  line-height: @baseLineHeight * 2;
}

h1 {
  font-size: @baseFontSize * 2.75;
} // ~38px
h2 {
  font-size: @baseFontSize * 2.25;
} // ~32px
h3 {
  font-size: @baseFontSize * 1.75;
} // ~24px
h4 {
  font-size: @baseFontSize * 1.25;
} // ~18px
h5 {
  font-size: @baseFontSize;
}
h6 {
  font-size: @baseFontSize * 0.85;
} // ~12px

h1 small {
  font-size: @baseFontSize * 1.75;
} // ~24px
h2 small {
  font-size: @baseFontSize * 1.25;
} // ~18px
h3 small {
  font-size: @baseFontSize;
}
h4 small {
  font-size: @baseFontSize;
}

// Page header
// -------------------------

.page-header {
  padding-bottom: (@baseLineHeight / 2) - 1;
  margin: @baseLineHeight 0 (@baseLineHeight * 1.5);
  border-bottom: 1px solid @grayLighter;
}

// Lists
// --------------------------------------------------

// Unordered and Ordered lists
ul,
ol {
  padding: 0;
  margin: 0 0 @baseLineHeight / 2 25px;
}
ul ul,
ul ol,
ol ol,
ol ul {
  margin-bottom: 0;
}
li {
  line-height: @baseLineHeight;
}

// Remove default list styles
ul.unstyled,
ol.unstyled {
  margin-left: 0;
  list-style: none;
}

// Single-line list items
ul.inline,
ol.inline {
  margin-left: 0;
  list-style: none;
  > li {
    display: inline-block;
    .ie7-inline-block();
    padding-left: 5px;
    padding-right: 5px;
  }
}

// Description Lists
dl {
  margin-bottom: @baseLineHeight;
}
dt,
dd {
  line-height: @baseLineHeight;
}
dt {
  font-weight: bold;
}
dd {
  margin-left: @baseLineHeight / 2;
}
// Horizontal layout (like forms)
.dl-horizontal {
  .clearfix(); // Ensure dl clears floats if empty dd elements present
  dt {
    float: left;
    width: @horizontalComponentOffset - 20;
    clear: left;
    text-align: right;
    .text-overflow();
  }
  dd {
    margin-left: @horizontalComponentOffset;
  }
}

// MISC
// ----

// Horizontal rules
hr {
  margin: @baseLineHeight 0;
  border: 0;
  border-top: 1px solid @hrBorder;
  border-bottom: 1px solid @white;
}

// Abbreviations and acronyms
abbr[title],
// Added data-* attribute to help out our tooltip plugin, per https://github.com/twbs/bootstrap/issues/5257
abbr[data-original-title] {
  cursor: help;
  border-bottom: 1px dotted @grayLight;
}
abbr.initialism {
  font-size: 90%;
  text-transform: uppercase;
}

// Blockquotes
blockquote {
  padding: 0 0 0 15px;
  margin: 0 0 @baseLineHeight;
  border-left: 5px solid @grayLighter;
  p {
    margin-bottom: 0;
    font-size: @baseFontSize * 1.25;
    font-weight: 300;
    line-height: 1.25;
  }
  small {
    display: block;
    line-height: @baseLineHeight;
    color: @grayLight;
    &:before {
      content: "\2014 \00A0";
    }
  }

  // Float right with text-align: right
  &.pull-right {
    float: right;
    padding-right: 15px;
    padding-left: 0;
    border-right: 5px solid @grayLighter;
    border-left: 0;
    p,
    small {
      text-align: right;
    }
    small {
      &:before {
        content: "";
      }
      &:after {
        content: "\00A0 \2014";
      }
    }
  }
}

// Quotes
q:before,
q:after,
blockquote:before,
blockquote:after {
  content: "";
}

// Addresses
address {
  display: block;
  margin-bottom: @baseLineHeight;
  font-style: normal;
  line-height: @baseLineHeight;
}
