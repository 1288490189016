@import "../../bootstrap/variables.less";
@import "global.variables.less";

body {
  font-family: @baseFontFamily;
  font-size: @baseFontSize;
  line-height: @baseLineHeight;
  font-weight: 100;
  -webkit-font-smoothing: antialiased;
  color: @offBlack;
}

// HEADINGS
// --------

h1 {
  font-size: 28px;
  margin-top: 20px;
}

h2 {
  font-size: 24px;
}

h3 {
  font-size: 20px;
}

h4 {
  font-size: 18px;
}

small {
  padding-left: 4px;
}

form h1 {
  font-size: 28px;
  margin: 15px 0;
}

.Bad {
  color: @textBad;
}

.Warning {
  color: @textWarning;
}

.Expected {
  color: @textExpected;
}

.Excellent {
  color: @textExcellent;
}
